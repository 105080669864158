;(function() {
/* @ngInject */
function GeneratorController(Helpers, UserService, ModalNotLoggedUser, NameGenerator, $timeout, IndexService, $window,
                             $rootScope) {
    let vm = this;

    let userService = UserService;
    let nameGenerator = NameGenerator;
    let indexService = IndexService;

    vm.description = "";
    vm.token = "";
    vm.checkBoxes = [];

    vm.checkName = "";
    vm.isCheckPage = false;

    vm.editorContent = {
        values: [],
        lastValue: ""
    };

    vm.boxes = [];
    vm.enumChecks = [];

    vm.onElementFocus = Helpers.onElementFocus;
    vm.generate = generate;
    vm.check = check;
    vm.checkOne = checkOne;
    vm.otherService = otherService;
    vm.createService = createService;
    vm.setCompanyName = setCompanyName;
    vm.clear = clear;
    vm.back = back;

    nameGenerator.getNameAvailabilityCheck().then(onNameAvailabilityCheckLoad);

    function onNameAvailabilityCheckLoad(data) {
        vm.enumChecks = angular.copy(data);
        vm.boxes = angular.copy(data);
    }

    function back() {
        vm.isCheckPage = !vm.isCheckPage;
        vm.boxes = angular.copy(vm.enumChecks);
    }

    function clear() {
        $rootScope.$broadcast('clear-keywords');
        vm.description = "";
        vm.generator.$setPristine();
        vm.generator.$setUntouched();
        vm.submitted = false;
        vm.names = undefined;
        Helpers.scrollTo("top");
    }

    function setCompanyName(box) {
        indexService.setCompanyName({name: vm.checkName, service: box.check});
    }

    function createService(box) {
        if(box.check === "WebSupportSk") {
            return "https://www.websupport.sk/cart/domain-checker?ref=NDwvGl07&qd=" + vm.checkName + ".sk";
        } else if(box.check === "WebSupportCom") {
            return "https://www.websupport.sk/cart/domain-checker?ref=NDwvGl07&qd=" + vm.checkName + ".com";
        } else if(box.check === "SpolocnostSRucenimObmedzenym") {
            return "/zalozenie-firmy/krok1";
        } else if(box.check === "ObcianskeZdruzenie") {
            return "/zalozenie-obcianskeho-zdruzenia/krok1";
        } else if(box.check === "Zivnost") {
            return "/zalozenie-zivnosti/krok1";
        } else if(box.check === "EuipoTrademark") {
            return "/ochranna-znamka";
        }
    }

    function otherService(box) {
        if(box.check === "WebSupportSk") {
            return "https://www.websupport.sk?ref=NDwvGl07";
        } else if(box.check === "WebSupportCom") {
            return "https://www.websupport.sk?ref=NDwvGl07";
        } else if(box.check === "SpolocnostSRucenimObmedzenym") {
            return "/zmeny-vo-firme";
        } else if(box.check === "ObcianskeZdruzenie") {
            return "/zmeny-v-obcianskom-zdruzeni";
        } else if(box.check === "Zivnost") {
            return "/zmena-zivnosti";
        } else if(box.check === "EuipoTrademark") {
            return "/ochranna-znamka";
        }
    }

    function checkOne(name, check, index) {
        vm.boxes[index].error = undefined;
        nameGenerator.check({name: name, nameAvailabilityCheck: check}).then((result) => {
            if (result) {
                vm.boxes[index] = result;
            } else {
                vm.boxes[index].error = true;
            }
        });
    }

    function check(name) {
        vm.isCheckPage = true;
        Helpers.scrollTo("top");
        vm.checkName = name;

        vm.boxes.forEach((box, index) => {
            checkOne(name, box.check, index);
        });
    }

    function generate() {
        nameGenerator.setGenerationAttemptCounter().then((count) => {
           if(count >= 4) {
               userService.getLoggedUser().then(onLoggedUserLoad);
           } else callGenerateService();
        });
    }

    function onLoggedUserLoad(user) {
        if (user) {
            callGenerateService();
        } else showLogginInfo();
    }

    function callGenerateService() {
        var element = document.querySelector('[name="vm.generator"]');
        var firstInvalid;

        if(vm.editorContent.values.length === 0 && vm.editorContent.lastValue.length === 0) {
            firstInvalid = element.querySelector("#keywordsId");
        } else {
            firstInvalid = element.querySelector('.ng-invalid');
        }

        if(firstInvalid) {
            vm.submitted=true;

            $timeout(function () {
                // if we find one, set focus
                if (firstInvalid) {
                    $('html, body').animate({
                        scrollTop: $("#" + firstInvalid.id.replace(/\./g, "\\\.")).offset().top - 200
                    }, 500);
                }
            });
        } else {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LcZ0HoaAAAAAGkLyJRbYM59jd6wKCKTaQ3-AmSF', {action: 'submit'}).then(function (token) {
                    if (vm.token === "") {
                        vm.token = token;
                    }
                    let model = {
                        style: "Serious",
                        keywords: vm.editorContent.values,
                        description: vm.description,
                        token: vm.token
                    }
                    if (vm.editorContent.lastValue !== "")
                        model.keywords.push(vm.editorContent.lastValue);

                    window.showSpinner(1);
                    nameGenerator.generate(model).then((result) => {
                        if (result) {
                            vm.names = result;
                            vm.token = "";

                            Helpers.scrollTo("generated-names");
                        }
                        window.hideSpinnerWhenAllResolved();
                    });
                });
            });
        }
    }

    function showLogginInfo() {
        ModalNotLoggedUser("generator.modal.title", "generator.modal.desc", "Name-Generator")
            .add()
            .result
            .then();
    }
}

angular.module("firmaren")
    .controller("GeneratorController", GeneratorController);
}());
